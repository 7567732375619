import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const Terms = ({location}) => (
  <Layout location={location}>
    <SEO title="Terms and Conditions" />
    <section className="container page">
      <div className="row">
        <div className="col-12">
          <h1>Terms of Use</h1>
          <p>The Precision PDR website is owned by Precision PDR and operated by DSS Digital. By accessing, browsing or using this website, you agree to the terms, conditions and disclaimers herein as amended from time to time.</p>
          <h3>Trademarks, Copyrights and Restrictions</h3>
          <p>All material on this site, including, but not limited to images, logos and illustrations, is protected by copyrights, trademarks, and other intellectual property rights which are owned and controlled by Precision PDR and its related companies. The content of www.precisionpdr.com.au is provided for your personal, non-commercial use. Such material may not be copied, republished, reproduced, uploaded, modified, transmitted, posted, or distributed in any way without the consent of Precision PDR</p>
          <p>The use of this website and these conditions is in accordance with the laws of Australia. Any legal action arising out of its use shall be brought and enforced under Australian laws. By using this site, you agree to submit to the jurisdiction of the courts of Australia and any legal action pursued by you shall be within the exclusive jurisdiction of the courts of Australia</p>
          <h3>Links to other sites</h3>
          <p>Precision PDR is not responsible for the privacy practices or the content of other sites that are linked to www.precisionpdr.com.au</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Terms